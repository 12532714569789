export const host = (() => {
  const environmentMappings = {
    test: 'https://fa-console-service.signalplus.net',
    production: 'https://fa-console-service.signalplus.com',
    development: 'http://localhost:8080'
  };
  const environment = process.env.BUILD_ENV || 'development';
  console.log('process.env.BUILD_ENV', process.env.BUILD_ENV);
  console.log('process.env.VERSION', process.env.VERSION);
  console.log('当前环境：', environment);
  return environmentMappings[environment];
})();
//user
export const loginUrl = `${host}/api/user/login`;
export const userInfoUrl = `${host}/api/user/userInfo`;
//project
export const projectUrl = `${host}/api/project/list`;
//switcher
export const switcherUrl = `${host}/api/switcher/list`;
export const switcherTurnOnUrl = `${host}/api/switcher/turn-on`;
export const switcherTurnOffUrl = `${host}/api/switcher/turn-off`;
//param
export const paramUrl = `${host}/api/param/list`;
export const addParamUrl = `${host}/api/param/add`;
export const updateParamUrl = `${host}/api/param/update`;
export const deleteParamUrl = `${host}/api/param/delete`;
//paramGroup
export const addParamGroupUrl = `${host}/api/paramGroup/add`;
export const updateParamGroupUrl = `${host}/api/paramGroup/update`;
export const deleteParamGroupUrl = `${host}/api/paramGroup/delete`;